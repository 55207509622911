import { render, staticRenderFns } from "./overview.vue?vue&type=template&id=2cc4f0d1&scoped=true"
import script from "./overview.vue?vue&type=script&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&lang=js"
export * from "./overview.vue?vue&type=script&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&lang=js"
import style0 from "./overview.vue?vue&type=style&index=0&id=2cc4f0d1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc4f0d1",
  null
  
)

export default component.exports