<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import referralApi from "@/apis/referralApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../date";
import Navbar2 from "../components/navbar2.vue";

/**
 * Index-1
 */
export default {
  props: {
    referralCode: {
      type: String,
      default: ''
    },
    partners: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      referral: undefined,
      referralLoad: 0,
      login: false,
      referralForm: {
        pageNum: 1,
        pageSize: 10
      },
      referralData: {},
      path: ""
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar2
  },
  created() {
    // this.path = location.href.replace("referrel","auth-signup")
    this.path = location.origin + `/auth-signup?ref=`;
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  methods: {

    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    }
  },
  filters: {
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    }
  },
  computed: {
    copyText() {
      return `${this.path}${this.referralCode}`;
    },
  },
};
</script>

<template>
  <div class="row mb-5">

    <div class="col-md-9 mt-4">
      <div style="width: 100%;background-color: #384247;padding: 30px 0;border-radius: 1.25rem;">
        <div style="width: 90%;margin: 0 auto;">
          <span style="color: #e8e8ff;font-weight: bold;font-size: 24px;"> Referral Link</span>
        </div>
        <div style="width: 90%;background-color: #e8e8ff;margin: 0 auto;border-radius: 25px;
		 padding: 10px 15px;display: flex;flex-flow: row;justify-content: space-between;
		 box-shadow: 0 0 3px white;margin-top: 20px;">
          <span class="notranslate" style="color: #0a0a1a;line-height: 37px;white-space: pre-wrap;word-wrap: break-word;word-break: break-all;">{{ path }}{{referralCode}}</span>
          <button class="window" block size="sm" v-clipboard:copy="copyText" v-clipboard:success="copysuccess"
                  v-clipboard:error="copyerror"
                  style="font-size: 18px;width: 100px;border-radius: 30px;background-color: #f6c5c5;color: black;border: none;padding: 5px 0;">
            Copy Link
          </button>
        </div>
        <div class="phone" style="text-align: center;margin-top: 20px;">
          <button block size="sm" v-clipboard:copy="copyText" v-clipboard:success="copysuccess"
                  v-clipboard:error="copyerror"
                  style="font-size: 18px;width: 90%;border-radius: 30px;background-color: #f6c5c5;color: black;border: none;padding: 10px 0;">
            Copy Link
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-3 mt-4">
      <div class="rank" style="text-align: center;border-radius: 1.25rem">
        <h6>Partners</h6>
        <h5>{{ partners }}</h5>
<!--        <img src="../assets/part.png" alt="Ranks">-->
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.rank {
  //background-color: #0a4499;
  background: #384247;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  position: relative;

  h6 {
    color: white;
  }

  h5 {
    color: #e8e8ff;
    margin: 25px 0 15px;
  }

  //img {
  //  position: absolute;
  //  z-index: -1;
  //  bottom: -25px;
  //  right: 25px;
  //  max-width: 8vw;
  //  height: auto;
  //}
}
</style>
