<script xmlns="http://www.w3.org/1999/html">
import {Carousel, Slide} from "vue-carousel";
import date, {parseTime} from "@/date";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/views/new-view/components/navbar";
// import Navbar2 from "@/components/navbar2";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import homeApi from "@/apis/homeApi";
import indexApi from "@/apis/indexApi";
import planApi from "@/apis/planApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import relink from "../components/relink.vue"
/**
 * Index-1
 */
export default {
  data() {
    return {
      url: "",
      imageLoad: true,
      wallet: undefined,
      dashBoard: {
        // vue.runtime.esm.js:1888 TypeError: Cannot read properties of undefined (reading 'profits')
        profits:null
      },
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        pageNum: 1,
        pageSize: 10
      },
      featuresData: [
        {
          icon: "uil uil-rocket",
          title: "150.5 Ph/s",
          description:
              "In management",
        },
        {
          icon: "uil uil-rss",
          title: "99.97%",
          description:
              "Uptime",
        },
        {
          icon: "uil uil-bitcoin-circle",
          title: "0%",
          description:
              "No commissions for withdrawal",
        },
        {
          icon: "uil uil-head-side-cough",
          title: "24/7",
          description:
              "Support",
        },
        {
          icon: "uil uil-bolt",
          title: "",
          description:
              "Mining starts immediately after payment",
        },
        {
          icon: "uil uil-wallet",
          title: "",
          description:
              "No service fees",
        },
        {
          icon: "uil uil-bitcoin-alt",
          title: "",
          description:
              "Supports USDT, BTC, ETH, and LTC wallet address withdrawal",
        },
        {
          icon: "uil uil-processor",
          title: "",
          description:
              "No need to buy equipment",
        }
      ],
      testimonialData: [
        {
          id: 1,
          profile: "images/client/01.jpg",
          message:
              "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
          name: "Thomas Israel",
          designation: "C.E.O",
        },
        {
          id: 2,
          profile: "images/client/02.jpg",
          message:
              "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
          name: "Barbara McIntosh",
          designation: "M.D",
        },
        {
          id: 3,
          profile: "images/client/03.jpg",
          message:
              "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
          name: "Carl Oliver",
          designation: "P.A",
        },
        {
          id: 4,
          profile: "images/client/04.jpg",
          message:
              "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
          name: "Christa Smith",
          designation: "Manager",
        },
        {
          id: 5,
          profile: "images/client/05.jpg",
          message:
              "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
          name: "Dean Tolle",
          designation: "Developer",
        },
        {
          id: 6,
          profile: "images/client/05.jpg",
          message:
              "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
          name: "Jill Webb",
          designation: "Designer",
        },
      ],
      bottom: "",
      userInfo: ''
    }
  },
  components: {
    Navbar,
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    // Navbar2,
	relink

  },
  created() {
    //获取Banner图片
    // homeApi.banner(res => {
    //   this.url = res.data.data
    // })
    //if login
    let userId = storage.localGet("userId")
    this.userInfo = userId
    if (userId) {
      this.login = true
      //获取仪表盘信息
      indexApi.dashBoard(res => {
        if (res.code !== 200) {
          if(res.code === 400){
            this.$message.error(res.msg)
          }else{
            storage.localDel("userId")
            this.login = false
          }
        } else {
          this.dashBoard = res.data
        }
      })
    }
    //未登录时显示100条数据
    if (!this.login) {
      this.planForm.pageSize = 10
    }
  },
  methods: {
    parseTime: parseTime,
  },
};
</script>

<template>
  <div class="overview">
    <section class="section pt-0" v-model="login" style="padding-bottom: 0;margin-top:50px;">

      <!--仪表盘-->
      <div class="container">
<!--        <div style="width: 80%;margin: 0 auto"  class="mt-4">-->
<!--          Dashboard <br>-->
<!--          Last activity : 19/11/2022 09:43:25 (203.218.129.143)-->
<!--        </div>-->

<!--        <div class="container-xl wide-xl">-->
<!--          <h4>Dashboard</h4>-->
<!--&lt;!&ndash;          <p class="font-12">Last Logon : {{userInfo && parseTime(userInfo.lastLoginAt, '{d}/{m}/{y} {h}:{i}:{s}')}}  ({{userInfo && userInfo.lastIp}}) </p>&ndash;&gt;-->
<!--          <p class="font-12">Last Logon : {{userInfo && parseTime(userInfo.lastLoginAt, '{d}/{m}/{y} {h}:{i}:{s}')}}  </p>-->
<!--        </div>-->


        <div class="row">
          <div class="col-md-6 mt-2 pt-0">
            <router-link to="/profile/deposits">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.5rem;color:white;">
                <h5 class="para">
                  Available Balance
                </h5>
                <h4 class="title notranslate">$ {{ dashBoard && dashBoard.balance }}</h4>
                <h5 class="para"> Trial Funds<br> </h5>
<!--                <h5>$ {{dashBoard && dashBoard.deposits}}</h5>-->
                <h4 class="notranslate" style="color: yellow;">$ {{ dashBoard.trialBalance ? dashBoard.trialBalance.toFixed(2) : '0.00' }} </h4>
<!--                <router-link to="/profile/myOrders" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
              </div>
            </div>
            </router-link>
          </div>
          <div class="col-md-6 mt-2 pt-0">
            <router-link to="/profile/withdraws">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.5rem;color:white;">
<!--                <p class="text-muted para">-->
                <h5 class="para">
                  Total Deposits
                </h5>
                <h4 class="title notranslate">$ {{ dashBoard.deposits ? dashBoard.deposits.toFixed(2) : '0.00' }}</h4>
                <h5 class="para"> Total Withdrawals <br> </h5>
                <h4 class="notranslate">$ {{ dashBoard.withdrawals ? dashBoard.withdrawals.toFixed(2) : '0.00' }}</h4>
<!--                <router-link to="/profile/myOrders" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
              </div>
            </div>
            </router-link>
          </div>
          <div class="col-md-4 mt-4 pt-2">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.25rem;color:white;">
                <!--                <p class="text-muted para">-->
                <h5 class="para">
                  Total Earned
                </h5>
                <h4 class="title notranslate"> $ {{ (dashBoard.profits + dashBoard.systems + dashBoard.referrals).toFixed(2) }} </h4>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-4 pt-2">
            <router-link to="/profile/transactions">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.25rem;color:white;">
<!--                <p class="text-muted para">-->
                <h5 class="para">
                  Profits
                </h5>
                <h4 class="title notranslate" style="color: greenyellow;"> $ {{ dashBoard.profits ? dashBoard.profits.toFixed(2) : '0.00' }}</h4>
<!--                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
              </div>
            </div>
            </router-link>
          </div>
          <div class="col-md-4 mt-4 pt-2">
            <router-link to="/profile/transactions">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.25rem;color:white;">
                <h5 class="para">
                  Referrals
                </h5>
                <h4 class="title notranslate">$ {{ dashBoard.referrals ? dashBoard.referrals.toFixed(2) : '0.00'}}</h4>
<!--                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
              </div>
            </div>
            </router-link>
          </div>
<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <router-link to="/profile/transactions">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body" style="background-color: #384247; border-radius: 0.25rem;color:white;">-->
<!--                <p class="para">-->
<!--                  Systems-->
<!--                </p>-->
<!--                <h4 class="title">$ {{ dashBoard.systems ? dashBoard.systems.toFixed(2) : '0.00' }}</h4>-->
<!--&lt;!&ndash;                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </router-link>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--            </router-link>-->
<!--          </div>-->
        </div>

<!--        <div class="row" v-if="dashBoard==undefined">-->
<!--        </div>-->
<!--        <div v-else class="row">-->
<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body">-->
<!--                <p class="text-muted para">-->
<!--                  Available Balance-->
<!--                </p>-->
<!--                <h4 class="title">${{ dashBoard.balance }}</h4>-->
<!--                <router-link to="/profile/myOrders" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->

<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body">-->
<!--                <p class="text-muted para">-->
<!--                  Active Plans-->
<!--                </p>-->
<!--                <h4 class="title">{{ dashBoard.activePlans }}</h4>-->
<!--                <router-link to="/profile/myOrders" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->



<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body">-->
<!--                <p class="text-muted para">-->
<!--                  Daily In Come-->
<!--                </p>-->
<!--                <h4 class="title">${{ dashBoard.dailyIncome }}</h4>-->
<!--                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end row&ndash;&gt;-->

<!--        <div class="row" v-if="dashBoard==undefined" v-loading="dashBoard==undefined"-->
<!--             element-loading-background="rgba(0, 0, 0, 0)">-->
<!--        </div>-->
<!--        <div v-else class="row">-->


<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body">-->
<!--                <p class="text-muted para">-->
<!--                  Deposits-->
<!--                </p>-->
<!--                <h4 class="title">${{ dashBoard.deposits }}</h4>-->
<!--                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->

<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body">-->
<!--                <p class="text-muted para">-->
<!--                  Referral Commission-->
<!--                </p>-->
<!--                <h4 class="title">{{ dashBoard.referralCommission }}</h4>-->
<!--                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->

<!--          <div class="col-md-4 mt-4 pt-2">-->
<!--            <div class="card work-process border-0 rounded shadow bg-light">-->
<!--              <div class="card-body">-->
<!--                <p class="text-muted para">-->
<!--                  Withdrawals-->
<!--                </p>-->
<!--                <h4 class="title">${{ dashBoard.withdrawals }}</h4>-->
<!--                <router-link to="/profile/transactions" class="text-primary">View All <i class="mdi mdi-chevron-right"></i>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->
<!--        </div>-->
      </div>
      <!--end container-->
    </section>


	  <relink :partners="dashBoard.partners" :referralCode="dashBoard.referralCode"></relink>


    <el-card class="box-card mt-4 mb-5" :body-style="{padding: 0}">
      <div slot="header" class="clearfix">
        <h4>My Orders</h4>
      </div>
      <!--     <el-button style="float: right; background-color: #3b506c" type="primary">More >> </el-button>     -->

      <div class="row">
        <div class="col-md-4 mt-4 pt-2">
          <router-link to="/profile/myOrders">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.25rem;color:white;">
                <h5 class="para">
                  Active
                </h5>
                <h4 class="title notranslate"> {{ dashBoard.activePlans }}</h4>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mt-4 pt-2">
          <router-link to="/profile/myOrders">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.25rem;color:white;">
                <h5 class="para">
                  Expired
                </h5>
                <h4 class="title notranslate"> {{ dashBoard.expiredPlans }}</h4>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 mt-4 pt-2">
          <router-link to="/profile/myOrders">
            <div class="card work-process border-0 rounded shadow bg-light">
              <div class="card-body" style="background-color: #384247; border-radius: 1.25rem;color:white;">
                <h5 class="para">
                  All
                </h5>
                <h4 class="title notranslate"> {{ dashBoard.activePlans + dashBoard.expiredPlans }}</h4>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </el-card>


<!--    <section>-->
<!--      <div class="container" style="background-color: #eee;margin:30px 0;padding: 15px;border-radius: 15px; margin-bottom:60px;">-->
<!--        <h4>Do you have any questions?</h4>-->
<!--        <p style="white-space: pre-wrap;word-wrap: break-word;word-break: break-all;">We strongly recommend that you search for the necessary information in the FAQ section. Contact our 24-hour online customer service if you need advice or technical assistance.</p>-->
<!--        <router-link to="/faq">-->
<!--          <button  block size="sm"-->
<!--             style="font-size: 18px;width: 100%;border-radius: 30px;background-color: #00b300;color: white;border: none;padding: 10px 0;">-->
<!--            Get Support Now-->
<!--          </button>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<style scoped lang="scss">
.container-xl {
  padding: 30px;
  background-color: rgba(59, 80, 108, 1);
  border-radius: 10px;
  //margin-bottom: 30px;
  margin-top: 2rem;
  color: #ffffff;
}
.overview{
  .el-card__header{
    border: none;

  }
  .el-card{
    border: none;
    box-shadow: none;

  }
}
</style>

